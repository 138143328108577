import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import DashboardIcon from '@material-ui/icons/Dashboard';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import RoomServiceIcon from '@material-ui/icons/RoomService';
import LockIcon from '@material-ui/icons/Lock';

import Layout from './Layout/Layout';
import NotFound from './Page/404';
import Introduction from './Page/Introduction';
import Limits from './Page/Limits';
import Roadmap from './Page/Roadmap';
import ConceptsReminder from './Page/ConceptsReminder';
import ConceptsServiceProvider from './Page/ConceptsServiceProvider';
import ConceptsTriggers from './Page/ConceptsTriggers';
import ServiceProviderWebhook from './Page/ServiceProviderWebhook';
import ServiceProviderEmail from './Page/ServiceProviderEmail';
import ServiceProviderSms from './Page/ServiceProviderSms';
import ApiReferenceKeys from './Page/ApiReferenceKeys';
import ApiReferenceReminders from './Page/ApiReferenceReminders';

import './App.css';


const DRAWER_LINKS = [
  {
    category: "OVERVIEW", links: [
      { Icon: <DashboardIcon fontSize="small" />, title: "INTRODUCTION", text: "Introduction", path: "/" },
      { Icon: <AlarmOnIcon fontSize="small" />,title: "LIMIT", text: "Limits", path: "/limits" },
      { Icon: <AlarmOnIcon fontSize="small" />,title: "ROADMAP", text: "Roadmap", path: "/roadmap" },
    ]
  },
  {
    category: "CONCEPTS", links: [
      { Icon: <AlarmOnIcon fontSize="small" />,title: "REMINDERS CONCEPT", text: "Reminders", path: "/concepts-reminders" },
      { Icon: <DashboardIcon fontSize="small" />, title: "TRIGGERS", text: "Triggers", path: "/concepts-triggers" },
      { Icon: <AlarmOnIcon fontSize="small" />,title: "SERVICE PROVIDER", text: "Service Providers", path: "/concepts-service-providers" },
    ]
  },
  {
    category: "SERVICE PROVIDERS", links: [
      { Icon: <AlarmOnIcon fontSize="small" />,title: "WEBHOOK", text: "Webhook", path: "/service-provider-webhook" },
      { Icon: <DashboardIcon fontSize="small" />, title: "EMAIL", text: "Email", path: "/service-provider-email" },
      { Icon: <AlarmOnIcon fontSize="small" />,title: "SMS", text: "Sms", path: "/service-provider-sms" },
    ]
  },
  {
    category: "API REFERENCE", links: [
      { Icon: <LockIcon fontSize="small" />,title: "API KEYS", text: "Api Keys", path: "/api-reference-keys" },
      { Icon: <RoomServiceIcon fontSize="small" />,title: "REMINDERS API", text: "Reminders", path: "/api-reference-reminders" }
    ]
  },
];


const App = () => {

  return (    
        <div style={{ height: "100%" }}>
          <Router>
            <Switch>
              <Route exact path="/">
                <Layout title="INTRODUCTION" drawerLinks={DRAWER_LINKS}>
                  <Introduction />
                </Layout>
              </Route>
              <Route exact path="/limits">
                <Layout title="LIMIT" drawerLinks={DRAWER_LINKS}>
                  <Limits />
                </Layout>
              </Route>
              <Route exact path="/roadmap">
                <Layout title="ROADMAP" drawerLinks={DRAWER_LINKS}>
                  <Roadmap />
                </Layout>
              </Route>
              <Route exact path="/concepts-reminders">
                <Layout title="REMINDERS CONCEPT" drawerLinks={DRAWER_LINKS}>
                  <ConceptsReminder />
                </Layout>
              </Route>
              <Route exact path="/concepts-triggers">
                <Layout title="TRIGGERS" drawerLinks={DRAWER_LINKS}>
                  <ConceptsTriggers />
                </Layout>
              </Route>
              <Route exact path="/concepts-service-providers">
                <Layout title="SERVICE PROVIDER" drawerLinks={DRAWER_LINKS}>
                  <ConceptsServiceProvider />
                </Layout>
              </Route>
              <Route exact path="/service-provider-webhook">
                <Layout title="WEBHOOK" drawerLinks={DRAWER_LINKS}>
                  <ServiceProviderWebhook />
                </Layout>
              </Route>
              <Route exact path="/service-provider-email">
                <Layout title="EMAIL" drawerLinks={DRAWER_LINKS}>
                  <ServiceProviderEmail />
                </Layout>
              </Route>
              <Route exact path="/service-provider-sms">
                <Layout title="SMS" drawerLinks={DRAWER_LINKS}>
                  <ServiceProviderSms />
                </Layout>
              </Route>
              <Route exact path="/api-reference-keys">
                <Layout title="API KEYS" drawerLinks={DRAWER_LINKS}>
                  <ApiReferenceKeys />
                </Layout>
              </Route>
              <Route exact path="/api-reference-reminders">
                <Layout title="REMINDERS API" drawerLinks={DRAWER_LINKS}>
                  <ApiReferenceReminders />
                </Layout>
              </Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Router>
        </div>
  );
}

export default App;
