import React from 'react';
import { Link } from "react-router-dom";
import { loader } from 'graphql.macro';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import Help from '@material-ui/icons/Help';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        height: "100%"
    },
    logo: {
        height: theme.spacing(7),
        width: theme.spacing(7),
    },
    logoTitle: {
        fontSize: theme.spacing(2),
        fontWeight: "bold",
        paddingRight: theme.spacing(2),
    },
    main: {
        height: "100%"
    },
    mainContentWrapper: {
        height: "100%"
    },
    mainContent: {
        marginTop: theme.spacing(4),
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
        marginBottom: theme.spacing(8), 
        overflow: "auto",
        height: "-webkit-fill-available"
    },
    menuButton: {
        alignSelf: 'flex-end',
        marginRight: theme.spacing(2),
    },
    actions: {
        flexGrow: 1,
        alignSelf: 'flex-end',
        justifyContent: 'flex-end',
        display: 'flex'
    },
    actionItem: {
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3)
    },
    search: {
        alignSelf: 'flex-end',
    },
    signout: {
        alignSelf: 'flex-end',

    },
    toolbar: {
        //   minHeight: 96,
        alignItems: 'flex-start',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingRight: theme.spacing(4),
        paddingLeft: theme.spacing(4),
        borderBottomColor: '#f2f2f2',
        borderBottomWidth: theme.spacing(1) / 8,
        borderBottomStyle: 'solid'
    },
    toolbarNav: {
        backgroundColor: '#003040',
        color: 'white',
        alignItems: 'flex-start',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingRight: theme.spacing(12),
        paddingLeft: theme.spacing(12),
    },
    sideNav: {
        color: '#003040',
        alignItems: 'flex-start',
        flexDirection: "column",

        // overflow: "visible",
        overflow: "auto",
        height: "-webkit-fill-available",

        backgroundColor: "white",
        boxShadow: "8px 8px 16px -16px pink",
        transition: "all .2s ease-in-out",
        "&:hover": {
        },
        "&:selected": {
            borderColor: "orange",
            borderWidth: theme.spacing(0.25),
            borderStyle: "solid",
        }
    },
    sideNavCategory: {
        color: '#9e9e9e',
        padding: theme.spacing(4),
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(2)

    },
    sideNavItemWrapper: {
        color: '#003040',
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(4),
        transition: "all .2s ease-in-out",
        "&:hover": {
            color: "#e91e63",
            borderLeftColor: "#e91e63",
            borderLeftWidth: theme.spacing(1.5),
            borderLeftStyle: "solid",

        }
    },
    sideNavItemWrapperSelected: {
        color: "#ffffff",
        backgroundColor: "#e91e63",
        borderLeftColor: "#e91e63",
        borderLeftWidth: theme.spacing(1.5),
        borderLeftStyle: "solid",
        "&:hover": {
            color: "#ffffff",
        }
    },
    sideNavItemWrapperMediaSm: {
        color: 'white',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        textAlign: "center",
        transition: "all .2s ease-in-out"
    },
    sideNavItemWrapperMediaSmSelected: {
        color: "#ffffff",
        backgroundColor: "#e91e63"
    },

    sideNavItem: {
        display: 'flex',
        alignItems: 'center'
    },
    sideNavItemLink: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1)
    },
    title: {
        flexGrow: 1,
        alignSelf: 'flex-end',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    navItem: {
        alignSelf: 'flex-end',
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
}));

export default function Layout(props) {
    const { title, children, drawerLinks } = props;
    const classes = useStyles();


    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="relative">
                <Toolbar className={classes.toolbar}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                    >
                        <img src="/sendontime.png" className={classes.logo} />
                        <span className={classes.logoTitle}>
                            sendontime 
                            {/* <Chip size="small" label="docs" color="primary"></Chip> */}
                        </span>
                        <Badge color="secondary" badgeContent={"docs"} showZero>
                            ""
                        </Badge>
                        
                    </Grid>

                    <div className={classes.actions}>
                    </div>

                </Toolbar>
            </AppBar>
            <main className={classes.main}>
                <Grid className={classes.mainContentWrapper} container>
                    <Hidden mdUp>
                        <Grid className={classes.sideNav} xs={2} sm={3} md={2} >
                            <Grid container>
                                {
                                    drawerLinks.map((drawer, key) => (
                                        <>
                                            {
                                                drawer.links.map((link, linkKey) => (
                                                    <Grid key={drawer.category + linkKey} className={link.title != title ? classes.sideNavItemWrapperMediaSm : classes.sideNavItemWrapperMediaSm + " " + classes.sideNavItemWrapperMediaSmSelected} item xs={12} >
                                                        <Link to={link.path} style={{ color: 'inherit', textDecoration: 'inherit' }}>{link.Icon}</Link>
                                                    </Grid>
                                                ))
                                            }
                                        </>
                                    ))
                                }

                            </Grid>
                        </Grid>
                    </Hidden>

                    <Hidden smDown>
                        <Grid className={classes.sideNav} xs={2} sm={3} md={2}>
                            <Grid container>
                                {
                                    drawerLinks.map((drawer, key) => (
                                        <>
                                            <Grid key={key} className={classes.sideNavCategory} item xs={12} >
                                                <Typography variant="caption" noWrap>
                                                    {drawer.category}
                                                </Typography>
                                            </Grid>
                                            {
                                                drawer.links.map((link, linkKey) => (
                                                    <Grid key={drawer.category + linkKey} className={link.title != title ? classes.sideNavItemWrapper : classes.sideNavItemWrapper + " " + classes.sideNavItemWrapperSelected} item xs={12} >
                                                        <Typography variant="button" className={classes.sideNavItem} noWrap>
                                                            {link.Icon}
                                                            <Link to={link.path} className={classes.sideNavItemLink} style={{ color: 'inherit', textDecoration: 'inherit' }}>{link.text}</Link>
                                                        </Typography>
                                                    </Grid>
                                                ))
                                            }
                                        </>
                                    ))
                                }
                                <>
                                    <Grid key="Others" className={classes.sideNavCategory} item xs={12} >
                                        <Typography variant="caption" noWrap>
                                            OTHERS
                                            </Typography>
                                    </Grid>
                                    <Grid key={"Others" + "HowToUse"} className={classes.sideNavItemWrapper} item xs={12} >

                                        <Typography variant="button" className={classes.sideNavItem} noWrap onClick={() => {
                                            console.log("How to use?");
                                        }}>
                                            <Help fontSize="small" />
                                            <span className={classes.sideNavItemLink}>HOW TO USE</span>
                                        </Typography>
                                    </Grid>
                                </>

                            </Grid>
                        </Grid>
                    </Hidden>

                    <Grid item className={classes.mainContent} xs={10} sm={9} md={10} >
                        {children}
                    </Grid>
                </Grid>
            </main>
        </div>
    );
}