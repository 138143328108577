import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    typography: {
        fontFamily: 'Rubik, Arial',
    },
    palette: {
        primary: {
            main: '#2196F3',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#f2f2f2',
        },
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                color: '#003040',
                backgroundColor: 'white',
                boxShadow: 'none'

            },
            background: 'white',
            root: {
                background: 'white'
            }
        },
        MuiDrawer: {
            paper:{
                background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                color: "white"
            }
        },
    },
});

export default theme;