import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(6),
    },
    pageHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(4)
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    fab: {
        position: "fixed",
        bottom: theme.spacing(6),
        right: theme.spacing(6)
    },
    headerTableItem: {
        alignSelf: "center"
    },
    listWrapper: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.25),
        boxShadow: "8px 8px 16px -16px pink",
        margin: theme.spacing(2),
        padding: theme.spacing(2),
        transition: "all .2s ease-in-out"
        // "&:hover": {
        //     transform: "scale(1.01)"
        // },
    },
    listItemWrapper: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.25),
        boxShadow: "8px 8px 16px -16px pink",
        margin: theme.spacing(0.25),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        padding: theme.spacing(1.5),
    },
    listItemHeader: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
        boxShadow: "8px 8px 16px -16px grey",
        marginBottom: theme.spacing(2),
        padding: theme.spacing(3),
    },
    listItemContent: {
        fontSize: "small",
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5)

    },
    listItemSubContent: {
        fontSize: "x-small",
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5)
    },
    listItem: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
        // boxShadow: "8px 8px 16px -16px grey",
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1),
        // paddingRight: theme.spacing(2),
        transition: "all .2s ease-in-out",
        "&:hover": {
            transform: "scale(1.01)"
        },
        "&:selected": {
            borderColor: "orange",
            borderWidth: theme.spacing(0.25),
            borderStyle: "solid",
        }
    },
    snippet: {
        fontSize: theme.spacing(1.5),
        // textAlign: "center"
    },
    snippetIcon: {
        fontSize: theme.spacing(2.5),
        color: "lightgrey",
        paddingTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    smallWidgetNav: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.25),
        boxShadow: "8px 8px 16px -16px pink",
        margin: theme.spacing(1),
        padding: theme.spacing(4),
        transition: "all .1s ease-in-out",
        "&:hover": {
            transform: "scale(1.01)",
            color: "#e91e63"
        },
    },
    smallWidget: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.25),
        boxShadow: "8px 8px 16px -16px pink",
        margin: theme.spacing(1),
        padding: theme.spacing(4),
        transition: "all .2s ease-in-out",
        "&:hover": {
            transform: "scale(1.01)"
        },
    },
    smallWidgetImage: {
        // height: theme.spacing(60)
        height: "100%",
        width: "100%"
    },
    smallWidgetBody:{
        padding: theme.spacing(2)
    },
    widget: {
        overflow: "visible",
        backgroundColor: "white",
        borderRadius: theme.spacing(0.5),
        boxShadow: "8px 8px 16px -16px pink",
        margin: theme.spacing(1),
        padding: theme.spacing(1),
        transition: "all .2s ease-in-out",
        "&:hover": {
            transform: "scale(1.03)"
        },
        "&:selected": {
            borderColor: "orange",
            borderWidth: theme.spacing(0.25),
            borderStyle: "solid",
        }
    }
}));

const Dashboard = props => {
    const classes = useStyles();

    return (
        <Grid container className={classes.root} spacing={3}>
            <Grid xs={12} className={classes.pageHeader}>
                <Typography variant="h6" noWrap>Introduction</Typography>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2} className={classes.smallWidget}>
                    <Grid item xs={12} ><Typography variant="subtitle2" align="left">What is sendontime ?</Typography></Grid>
                    <Grid item xs={12} className={classes.smallWidgetBody}>
                        <Typography variant="body2" align="justify" paragraph={true} className={classes.smallWidgetBody}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2} className={classes.smallWidget}>
                    <Grid item xs={12} ><Typography variant="subtitle2" align="left">How it works ?</Typography></Grid>
                    <Grid item xs={12} md={6} >
                        <Typography variant="body2" align="justify" paragraph={true} className={classes.smallWidgetBody}>
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} >
                        <img src="/images/API.gif" className={classes.smallWidgetImage} />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={2} className={classes.smallWidget}>
                    <Grid item xs={12} ><Typography variant="subtitle2" align="left">Possible use cases</Typography></Grid>
                    <Grid item xs={12} className={classes.smallWidgetBody}>
                        <Typography variant="body2" align="justify" paragraph={true} className={classes.smallWidgetBody}>
                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container direction="row" justify="space-evenly" alignItems="center">
                    <Grid item xs={12} md={3}>
                        <Grid container spacing={2} className={classes.smallWidgetNav}>
                            <Grid item xs={12} ><Typography variant="subtitle2" align="center">Next Page</Typography></Grid>
                            <Grid item xs={12} >
                                <Typography variant="h4" align="center" noWrap>Limits</Typography>
                            </Grid>
                            <Grid item xs={12} align="center"><Typography variant="caption">Understand limits and pricing mechanics while using API</Typography></Grid>

                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <Grid container spacing={2} className={classes.smallWidgetNav}>
                            <Grid item xs={12} ><Typography variant="subtitle2" align="center">For Developers</Typography></Grid>
                            <Grid item xs={12} >
                                <Typography variant="h4" align="center" noWrap>API Guide</Typography>
                            </Grid>
                            <Grid item xs={12} align="center"><Typography variant="caption">API reference for developers and various use case</Typography></Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

        </Grid>
    )
}

export default Dashboard;